export const fetchPlacesAutocomplete = (searchKeyword, location) => {
    return new Promise((resolve,reject)=>{
        const baseURL = 'https://suggest-maps.yandex.ru/v1/suggest';
        var queryParams = [
          'apikey=bb4ccae2-237c-44ce-8b6b-80391140c2b7',
          `text=${encodeURIComponent(searchKeyword)}`,
          'lang=ru',
          'types=street,district,locality,house,biz',
          'attrs=uri',
        ];
        if (location && location.lat && location.lon) {
          queryParams.push(`ll=${location.lon},${location.lat}`);
          queryParams.push('spn=0.35,0.45');
        }
        const url = baseURL + '?' + queryParams.join('&');
        console.log(url);
        fetch(url, {
            method: 'GET'
        }).then(response => {
            return response.json();
        })
        .then(json => {
            if (json && json.results) {
                resolve(json.results);
            } else {
                reject(json.error);
            }
        }).catch(error=>{
            console.log(error);
            reject("fetchPlacesAutocomplete Call Error")
        })
    });
}

export const fetchCoordsfromPlace = (uri) => {
  return new Promise((resolve,reject)=>{
      const url = new URL(`https://geocode-maps.yandex.ru/1.x`);
      url.searchParams.append('apikey', '14889688-9e04-41b0-81ba-a1817863b8b2');
      url.searchParams.append('format', 'json');
      url.searchParams.append('uri', uri);
      fetch(url, {
          method: 'GET'
      }).then(response => {
          return response.json();
      })
      .then(json => {
          if (json && json.response && json.response.GeoObjectCollection && json.response.GeoObjectCollection.featureMember[0]) {
              const rawPoint = json.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos;
              const [lng, lat] = rawPoint.split(' ').map(Number);
              resolve({lat: lat, lng: lng});
          } else {
              reject(json.error);
          }
      }).catch(error=>{
          console.log(error);
          reject("fetchCoordsfromPlace Call Error")
      })
  });
}

export const fetchAddressfromCoords = (lnglat) => {
  return new Promise((resolve,reject)=>{
      const url = new URL(`https://geocode-maps.yandex.ru/1.x`);
      url.searchParams.append('apikey', '14889688-9e04-41b0-81ba-a1817863b8b2');
      url.searchParams.append('geocode', lnglat);
      url.searchParams.append('kind', 'house');
      url.searchParams.append('format', 'json');
      fetch(url, {
          method: 'GET',
      }).then(response => {
          return response.json();
      })
      .then(json => {
          if(json && json.response && json.response.GeoObjectCollection.featureMember[0]) {
              resolve(json.response.GeoObjectCollection.featureMember[0].GeoObject.name);
          }else{
              reject(json.error);
          }
      }).catch(error=>{
          console.log(error);
          reject("fetchAddressfromCoords Call Error")
      })
  });
}

export const getDirectionsApi = (waypoints) => {
    return new Promise((resolve,reject) => {
        const url = new URL(`https://api.routing.yandex.net/v2/route`);
        url.searchParams.append('apikey', 'eea5936b-18e9-4f49-83ec-fdda5edb3252');
        url.searchParams.append('waypoints', waypoints);
        fetch(url, {
            method: 'GET',
        }).then(response => {
            return response.json();
        })
        .then(json => {
            if (json && json.route && json.route.legs && json.route.legs.length > 0) {
                const firstLeg = json.route.legs[0];
                const result = {
                    distance_in_km: firstLeg.steps.reduce((acc, step) => acc + step.length, 0) / 1000,
                    time_in_secs: firstLeg.steps.reduce((acc, step) => acc + step.duration, 0),
                    polylinePoints: firstLeg.steps.flatMap(step => step.polyline.points)
                };
                resolve(result);
            } else {
                console.log(json.errors);
                reject(json.errors);
            }
        }).catch(error=>{
            console.log(error);
            reject("getDirectionsApi Call Error")
        })
    });
}

export const getDistanceMatrix = (startLoc, destLoc) => {
    return new Promise((resolve,reject)=>{
        const url = new URL(`https://api.routing.yandex.net/v2/distancematrix`);
        url.searchParams.append('apikey', 'eea5936b-18e9-4f49-83ec-fdda5edb3252');
        url.searchParams.append('origins', startLoc);
        url.searchParams.append('destinations', destLoc);
        fetch(url, {
            method: 'GET',
        }).then(response => {
            return response.json();
        })
        .then(json => {
            if (json && json.rows && json.rows.length > 0) {
                const elements = json.rows[0].elements;
                const result = elements.map(el => {
                    if (el.status == "OK") {
                        return {found: true, timein_text: (el.duration.value / 60).toFixed(0) + ' мин'};
                    } else {
                        return {found: false};
                    }
                })
                resolve(result);
            } else {
                console.log(json.errors);
                reject(json.errors);
            }
        }).catch(error=>{
            console.log(error);
            reject("getDistanceMatrix Call Error")
        })
    });
}