export const FirebaseConfig = {
	"projectId": "aqzhol-app",
	"appId": "1:923843993580:web:bab06ea9e5446da2632ac9",
	"databaseURL": "https://aqzhol-app-default-rtdb.europe-west1.firebasedatabase.app",
	"storageBucket": "aqzhol-app.appspot.com",
	"locationId": "asia-south1",
	"apiKey": "AIzaSyCXnT8L6qKNMx3_S8U8YeVQHh2JScnF-cs",
	"authDomain": "aqzhol-app.firebaseapp.com",
	"messagingSenderId": "923843993580",
	"measurementId": "G-Q0KLHLZBH4"
};